var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"is-size-6 p-3"},[_vm._v(" "+_vm._s(_vm.stringsInsurance.claim_per_day_title)+" ")]),_c('table-ui',{staticClass:"mb-0",attrs:{"head":{
      date: { text: _vm.stringsInsurance.claim_date_title, classes: 'px-3' },
      claim: {
        text: _vm.stringsInsurance.claim_value_title,
        align: 'right',
        classes: 'px-3'
      },
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"px-0",attrs:{"colspan":"2"}},[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',[_vm._v(_vm._s(_vm.$moment(item.date).format('DD MMM YYYY')))]),_c('div',{staticClass:"wet-note"},[_vm._v(" "+_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.weather_type[item.type] + ':')+"   "),(item.threshold !== null)?_c('span',[_vm._v(" "+_vm._s(item.threshold)+_vm._s(_vm.strings.commonApp && _vm.strings.commonApp.unit[item.type])+" ")]):_vm._e(),(item.threshold !== null && item.status === 'o')?_c('span',[_vm._v(" "+_vm._s(_vm.stringsInsurance.data_not_complete)+" ")]):_vm._e(),(item.threshold == null)?_c('span',{attrs:{"title":_vm.strings.commonApp && _vm.strings.commonApp.not_available}},[_vm._v(" "+_vm._s(_vm.stringsInsurance.no_measurement)+" ")]):_vm._e(),(item.status === 'p')?_c('span',[_vm._v(" "+_vm._s(_vm.format(_vm.stringsInsurance.data_updates_tpl, item.lastHour))+" ")]):_vm._e()])]),(item.claim !== null && item.status !== 'o')?_c('div',{staticClass:"flex-shrink-1 font-weight-bold"},[_c('money-field',{attrs:{"value":item.claim,"dynamic-cents":true,"currency":_vm.currency}})],1):_vm._e()]),_c('div',{staticClass:"text-right font-weight-bold wet-note px-3 pb-3"},[(item.id)?_c('div',[_vm._v("Claim ID: "+_vm._s(item.id))]):_vm._e(),(item.noClaim && item.status !== 'o')?_c('div',[_vm._v(_vm._s(_vm.reason(item.days)))]):_vm._e()])])])])})},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }